import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { mobileBreakpoint } from '../common';

const BandC = styled.h1`
  margin: 0;
  color: white;
  font-family: 'Cinzel', serif;
  font-weight: 400;
  font-size: 3rem;
  text-align: center;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.85);
  line-height: 1;

  ${mobileBreakpoint`
    margin-bottom: 0.5rem;
  `}
`;

const Deetz = styled.div`
  color: white;
  font-family: 'Cinzel', serif;
  font-weight: 400;
  font-size: 1.5rem;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.85);
  line-height: 1.25;
`;

export interface HeroProps {
  image: any;
  toggleHalloween(): void;
  toggleNameOrder(): void;
  nameOrder: string;
  halloweenMode: boolean;
}

const StyledImg = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: -100;
  background-size: cover;
`;
const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-family: 'Cinzel', serif;
  padding: 1rem;
  text-align: center;
`;

export const Hero: React.SFC<HeroProps> = ({
  image,
  toggleNameOrder,
  toggleHalloween,
  nameOrder,
  halloweenMode,
}) => {
  return (
    <>
      <StyledImg image={image} alt='hero image' loading='eager'></StyledImg>
      <TextContainer>
        <BandC onClick={toggleNameOrder}>{nameOrder}</BandC>
        <Deetz onClick={toggleHalloween}>
          {halloweenMode && '🎃 '}
          October 30, 2021
          {halloweenMode && ' 🎃'}
        </Deetz>
        <Deetz>Santa Rosa, California</Deetz>
      </TextContainer>
    </>
  );
};
