import React from 'react';
import styled from 'styled-components';

import { Script, PaddedContainer, HorizRule } from '../common';

const Container = styled.div`
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.25rem;
  text-align: left;
`;

const SectionHeading = styled.div`
  font-size: 1.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin-bottom: 0.5rem;
`;

const SectionSubHeading = styled(SectionHeading)`
  font-size: 1.25rem;
  margin-bottom: 0;
`;

export const Covid = () => (
  <PaddedContainer>
    <Script>COVID Protocols</Script>
    <Container>
      <ul>
        <li>
          <strong>
            All unvaccinated guests are required to take a rapid COVID test
            prior to attending any of the weekend’s festivities.
          </strong>
        </li>
        <li>
          All guests, regardless of vaccination status, are required to wear a
          mask on the shuttle bus between the hotel and the wedding venue.
        </li>
        <li>
          Bay Area counties require proof of vaccination for indoor dining. We
          recommend taking a picture of your card before coming if you plan on
          going to any restaurants or bars in the area.
        </li>
      </ul>
    </Container>
    <br />
    <HorizRule />
    <br />
    <Script>Venue Rules</Script>
    <Container>
      <ul>
        <li>
          Due to wildfire risk, there is{' '}
          <strong>no smoking allowed at the venue</strong>.
        </li>
        <li>
          No personal vehicles, Ubers, or taxis will be allowed on the property.
          Please make sure to be on time for the shuttle or you will miss the
          event.
        </li>
      </ul>
    </Container>
  </PaddedContainer>
);
