import 'normalize.css';

import React, { useState, useRef, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ImgBackgroundSlider from 'gatsby-image-background-slider';
import { lighten } from 'polished';

import SEO from '../components/seo';
import { FullPgBg, MinFullPgBg, Section } from '../components/common';
import { MobileResponsiveHeader } from '../components/Header';
import { OurStorySection } from '../components/OurStory';
import { WeddingInfo } from '../components/WeddingInfo';
import { Hero } from '../components/Hero';
import { GettingThere } from '../components/GettingThere';
import { Registry } from '../components/Registry';
import { getImage } from 'gatsby-plugin-image';
import { ScheduleSection } from '../components/Schedule';
import { Covid } from '../components/Rona';

const callbacks = {};

const IndexPage = () => {
  const { galleryBgs, heroImg, mapImg } = useStaticQuery(graphql`
    {
      mapImg: file(relativePath: { eq: "map-upscaled.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      galleryBgs: allFile(filter: { relativePath: { glob: "gallery/*" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              quality: 70
              placeholder: NONE
              layout: FULL_WIDTH
              formats: [AUTO, AVIF, WEBP]
              breakpoints: [750, 1080, 1280, 1366, 1920]
            )
          }
        }
      }
      heroImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            quality: 80
            layout: FULL_WIDTH
            formats: [AUTO, AVIF, WEBP]
            breakpoints: [750, 1080, 1280, 1366, 1920]
          )
        }
      }
    }
  `);

  const [cFirst, setCFirst] = useState(true);
  const [halloween, setHalloween] = useState(false);

  const ronaRef = useRef<HTMLDivElement>(null);
  const ourStoryRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  const scheduleRef = useRef<HTMLDivElement>(null);
  const gettingThereRef = useRef<HTMLDivElement>(null);
  const registryRef = useRef<HTMLDivElement>(null);

  const scrollTo = useCallback(
    (section: Section) => () => {
      if (section === Section.top) {
        window.scroll({ behavior: 'smooth', top: 0 });
        return;
      }

      const map = {
        [Section.rona]: ronaRef,
        [Section.ourStory]: ourStoryRef,
        [Section.gallery]: galleryRef,
        [Section.schedule]: scheduleRef,
        [Section.gettingThere]: gettingThereRef,
        [Section.registry]: registryRef,
      };
      const ref = map[section];
      const offset = ref.current.offsetTop;
      window.scroll({ behavior: 'smooth', top: offset });
    },
    [
      ronaRef,
      ourStoryRef,
      galleryRef,
      scheduleRef,
      gettingThereRef,
      registryRef,
    ]
  );

  const names = halloween ? ['🧛‍♀️', '🧟‍♂️'] : ['Caroline', 'Brett'];
  if (!cFirst) names.reverse();

  const nameOrder = names.join(' & ');
  const logoOrder = cFirst ? 'C&B' : 'B&C';
  const heroImgMerged = getImage(heroImg);

  return (
    <>
      <SEO title={'Caroline and Brett'} />
      <FullPgBg>
        <Hero
          image={heroImgMerged}
          toggleNameOrder={() => setCFirst(prev => !prev)}
          toggleHalloween={() => setHalloween(h => !h)}
          halloweenMode={halloween}
          nameOrder={nameOrder}
        />
      </FullPgBg>
      <MobileResponsiveHeader scrollTo={scrollTo} logoOrder={logoOrder} />
      <MinFullPgBg ref={ourStoryRef} bgColor='#ecf8f2'>
        <OurStorySection />
      </MinFullPgBg>
      <MinFullPgBg ref={ronaRef} bgColor='#ecf2f8'>
        <Covid />
      </MinFullPgBg>
      <FullPgBg ref={galleryRef}>
        <ImgBackgroundSlider
          callbacks={callbacks}
          query={{ backgrounds: galleryBgs }}
          alt='gallery images'
        ></ImgBackgroundSlider>
      </FullPgBg>
      <MinFullPgBg ref={scheduleRef} bgColor='#ecf8f2'>
        <ScheduleSection />
      </MinFullPgBg>
      <MinFullPgBg ref={gettingThereRef} bgColor='#ecf2f8'>
        <GettingThere isHalloween={halloween} />
      </MinFullPgBg>
      <MinFullPgBg ref={registryRef} bgColor='#ecf8f2'>
        <Registry />
      </MinFullPgBg>
    </>
  );
};

export default IndexPage;
