import React from 'react';
import styled from 'styled-components';

import { Script, PaddedContainer, Href, HorizRule } from '../common';

const Container = styled.div`
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.25rem;
`;

const SectionHeading = styled.div`
  font-size: 1.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin-bottom: 0.5rem;
`;

const SectionSubHeading = styled(SectionHeading)`
  font-size: 1.25rem;
  margin-bottom: 0;
`;

const RecLink = styled(Href)`
  display: block;
  margin: 0 auto;
  max-width: 25rem;
`;

export const ScheduleSection = () => (
  <PaddedContainer>
    <Script>Schedule</Script>
    <Container>
      <HorizRule />
      <br />
      <div>
        <SectionSubHeading>Looking for things to do?</SectionSubHeading>
        <RecLink href='https://docs.google.com/document/d/1ajx2f9OIdO2ykQSFpjRANdQ3yOglsPyrnOT_XW9dMf0/edit'>
          View some recommendations for some favorite cafes, wineries and
          restaurants.
        </RecLink>
      </div>
      <br />
      <HorizRule />
      <br />
      <SectionHeading>Friday 10/29</SectionHeading>
      <SectionSubHeading>Welcome Party @</SectionSubHeading>
      <SectionSubHeading>
        <Href href='https://goo.gl/maps/RPuCzCmoZdog9ZqD7'>
          D'Argenzio Winery
        </Href>
      </SectionSubHeading>
      <SectionSubHeading>6:30 PM - 8:30 PM</SectionSubHeading>
      Join us for appetizers, wood-fired pizza, and drinks.
      <br />
      <br />
      <HorizRule />
      <br />
      <SectionHeading>Saturday 10/30</SectionHeading>
      <SectionSubHeading>Shuttle</SectionSubHeading>
      <SectionSubHeading>3:15 PM</SectionSubHeading>
      The Shuttle will be leaving from the Hyatt at 3:15 PM.
      <br />
      <strong>
        Please make sure you are on time, as you will not be able to take
        alternate transportation.
      </strong>
      <br />
      <br />
      <SectionSubHeading>Ceremony & Reception @</SectionSubHeading>
      <SectionSubHeading>Annadel Estate Winery</SectionSubHeading>
      <SectionSubHeading>3:30 PM - 10:00 PM</SectionSubHeading>
    </Container>
  </PaddedContainer>
);
