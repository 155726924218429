import React from 'react';
import styled from 'styled-components';

import { PaddedContainer, Script, Href, HorizRule } from '../common';

const PaddedContainerNoWidth = styled(PaddedContainer)`
  max-width: 900px;
  padding: 0;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 100%;
`;

const TravelContainer = styled.div`
  max-width: 440px;
  width: 100%;
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.25rem;
  color: rgb(69, 69, 69);
  padding: 0.5rem;
  margin: 0.5rem;
`;

const SectionHeading = styled.div`
  font-size: 1.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
`;

const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: 500;
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const HotelInfo = styled.div`
  line-height: 1.75;
  margin-bottom: 1.25rem;
`;

const ButtonContainer = styled.a`
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  display: block;
`;

interface GettingThereProps {
  isHalloween: boolean;
}

export const GettingThere: React.SFC<GettingThereProps> = ({ isHalloween }) => (
  <PaddedContainerNoWidth>
    <Script>Getting There</Script>
    <FlexContainer>
      <TravelContainer>
        <SectionHeading>Air Travel</SectionHeading>
        San Francisco International Airport (SFO) (75 min) is the largest
        airport nearby. Other options include Oakland (OAK) (70 min), San Jose
        (SJC) (100 min), and Sacramento (SMF) (100 min).
        <br />
        <br />
        The Charles M. Schulz–Sonoma County Airport (STS) is about 15 minutes
        from the hotel and has non-stop flights to/from the West Coast and
        Denver. For travellers from these areas, this might be a better option.
        <br />
        <br />
        Use <Href href='https://flights.google.com'>Google Flights</Href> to
        find the cheapest and best flights that work for you.
      </TravelContainer>
      <HorizRule />
      {isHalloween && (
        <>
          <TravelContainer>
            <SectionHeading>Travel by Broom</SectionHeading>
            All witches may fly directly to the venue and can park their brooms
            in the parking lot. <br />
            🧹🧙‍♀️
          </TravelContainer>
          <HorizRule />
        </>
      )}
      <TravelContainer>
        <SectionHeading>Lodging</SectionHeading>
        <HotelInfo>
          <strong>Hyatt Regency Sonoma Wine Country</strong>
          <br />
          170 Railroad Street
          <br />
          Santa Rosa, California 95401
        </HotelInfo>
        There is a block of rooms reserved, which can be booked through the link
        below:
        <ButtonContainer href='https://www.hyatt.com/en-US/group-booking/SONOM/G-WNRW'>
          <Button>Info & Reservations</Button>
        </ButtonContainer>
      </TravelContainer>
      <HorizRule />
      <TravelContainer>
        <SectionHeading>Transportation</SectionHeading>
        Outside vehicles (including taxis and Ubers) are{' '}
        <strong>not allowed</strong> at the venue. Shuttle transportation will
        be provided from the Hyatt to the venue. If staying elsewhere, please
        arrange transportation to and from the Hyatt.
      </TravelContainer>
    </FlexContainer>
  </PaddedContainerNoWidth>
);
