import React from 'react';
import styled from 'styled-components';

import { PaddedContainer } from '../common';

const Container = styled(PaddedContainer)`
  color: rgb(69, 69, 69);
  font-size: 1.25rem;
`;

const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: 500;
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const Registry = () => (
  <Container>
    <a href='https://www.amazon.com/wedding/share/bcjurgens'>
      <Button>Visit Registry</Button>
    </a>
  </Container>
);
