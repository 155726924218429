import React from 'react';
import styled from 'styled-components';

import { Script, PaddedContainer } from '../common';

const Container = styled.div`
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.25rem;
`;

export const OurStorySection = () => (
  <PaddedContainer>
    <Script>Our Story</Script>
    <Container>
      Our love story began in the beautiful town of Champaign, IL back in 2012.
      We met the way every couple dreams of &mdash; at a college fraternity
      party as freshmen. We're forever indebted to our good friend Emily who not
      only brought Caroline to said fraternity party, but also encouraged
      forgiveness after Brett accidentally stabbed Caroline with a lightsaber
      within the first ten minutes of meeting.
      <br />
      <br />
      Eight years later we’ve moved across the country, traveled the globe, and
      adopted the cutest pup in the world. A few months after moving to San
      Francisco, Brett finally popped the question while hiking in the Redwoods,
      and Caroline said yes. We can’t wait for this exciting new chapter to
      begin!
    </Container>
  </PaddedContainer>
);
