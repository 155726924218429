import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export enum Section {
  top,
  rona,
  ourStory,
  gallery,
  schedule,
  gettingThere,
  registry,
}

export const mobileBreakpoint: typeof css = (...args) => css`
  @media (max-width: 650px) {
    ${
      // @ts-ignore
      css(...args)
    }
  }
`;

export const FullPgBg = styled.div<{ bgColor?: string }>`
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  background-color: ${({ bgColor = 'transparent' }) => bgColor};
  display: flex;
  align-items: center;
`;

export const MinFullPgBg = styled(FullPgBg)`
  height: auto;
  min-height: 100vh;
  padding: 3.25rem 0 1rem;
`;

export const PaddedContainer = styled.div`
  padding: 1rem;
  max-width: 600px;
  width: 100%;
  width: 100vw;
  text-align: center;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2;
  flex-direction: column;
`;

export const Script = styled.div`
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  color: rgb(69, 69, 69);
  font-family: 'Dancing Script', cursive;
`;

export const DesktopOnly = styled.div`
  @media (max-width: 650px) {
    display: none;
  }
`;

export const AbsoluteCenterAlign = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Href = styled.a`
  color: rgb(69, 69, 69);
  text-decoration-color: rgba(69, 69, 69, 0.15);
  transition: 0.25s;

  &:hover {
    color: rgb(15, 15, 15);
    text-decoration-color: rgba(15, 15, 15, 0.75);
  }
`;

export const HorizRule = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0)
  );
  width: 100%;
`;
