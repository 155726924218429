import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { DesktopOnly, Section, mobileBreakpoint } from '../common';

const stickyHeader = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const hamburgerIconSvg =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMzJweCIgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHdpZHRoPSIzMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNCwxMGgyNGMxLjEwNCwwLDItMC44OTYsMi0ycy0wLjg5Ni0yLTItMkg0QzIuODk2LDYsMiw2Ljg5NiwyLDhTMi44OTYsMTAsNCwxMHogTTI4LDE0SDRjLTEuMTA0LDAtMiwwLjg5Ni0yLDIgIHMwLjg5NiwyLDIsMmgyNGMxLjEwNCwwLDItMC44OTYsMi0yUzI5LjEwNCwxNCwyOCwxNHogTTI4LDIySDRjLTEuMTA0LDAtMiwwLjg5Ni0yLDJzMC44OTYsMiwyLDJoMjRjMS4xMDQsMCwyLTAuODk2LDItMiAgUzI5LjEwNCwyMiwyOCwyMnoiLz48L3N2Zz4=';

const HeaderItem = styled.div`
  padding: 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Hamburger = styled(HeaderItem)`
  display: none;
  height: 50px;

  ${mobileBreakpoint`
    display: block;
  `}
`;

interface StyledHeaderProps {
  hamburgerOpen: boolean;
  sticky: boolean;
}

const Header = styled.header<StyledHeaderProps>`
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  font-family: 'Cormorant Garamond', serif;
  color: rgb(69, 69, 69);
  text-transform: uppercase;

  ${({ sticky }) => sticky && stickyHeader}

  ${mobileBreakpoint<StyledHeaderProps>`
    background: rgba(255, 255, 255, 0.9);
    flex-direction: column;
    text-align: center;

    ${({ sticky }) =>
      !sticky &&
      css`
        display: none;
      `}

    ${({ hamburgerOpen }) =>
      !hamburgerOpen &&
      css`
        border-bottom: none;

        ${HeaderItem} {
          display: none;
          height: 50px;
        }

        ${Hamburger} {
          display: block;
        }
      `}
  `}
`;

const MobileHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  user-select: none;

  ${mobileBreakpoint`
      border-bottom: 1px solid #ccc;
    `}
`;

const MobileHeaderLogo = styled.div`
  padding: 1rem;
  font-family: 'Dancing Script', cursive;
  letter-spacing: 0.2rem;
  cursor: pointer;
`;

const MobileHeaderBuffer = styled(DesktopOnly)`
  height: 51px;
`;

const HeaderItems = styled.div`
  display: flex;

  ${mobileBreakpoint`
      background: rgba(255, 255, 255, 0.9);
      flex-direction: column;
      text-align: center;
    `}
`;

export interface HeaderProps {
  scrollTo(section: Section): () => void;
  logoOrder: string;
}

type WindowWithVV = window & { visualViewport: { height: number } };

export const MobileResponsiveHeader: React.SFC<HeaderProps> = ({
  scrollTo,
  logoOrder,
}) => {
  const [isSticky, setSticky] = useState(false);
  const [isHamburgerOpen, setHamburger] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof headerRef === 'object' && headerRef?.current) {
        const height =
          (window as WindowWithVV).visualViewport?.height ?? window.innerHeight;
        const hitSpot = window.pageYOffset >= height;
        setSticky(hitSpot);

        // Close the hamburger menu if it's now hidden.
        !hitSpot && setHamburger(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Header ref={headerRef} sticky={isSticky} hamburgerOpen={isHamburgerOpen}>
        <MobileHeaderContainer>
          <MobileHeaderLogo onClick={scrollTo(Section.top)}>
            {logoOrder}
          </MobileHeaderLogo>
          <Hamburger onClick={() => setHamburger(prev => !prev)}>
            <img
              src={hamburgerIconSvg}
              style={{
                height: '1rem',
                width: '1rem',
              }}
              alt='Hamburger menu'
            />
          </Hamburger>
        </MobileHeaderContainer>
        <HeaderItems>
          <HeaderItem onClick={scrollTo(Section.ourStory)}>
            Our Story
          </HeaderItem>
          <HeaderItem onClick={scrollTo(Section.rona)}>COVID</HeaderItem>
          <HeaderItem onClick={scrollTo(Section.gallery)}>Gallery</HeaderItem>
          <HeaderItem onClick={scrollTo(Section.schedule)}>Schedule</HeaderItem>
          <HeaderItem onClick={scrollTo(Section.gettingThere)}>
            Travel
          </HeaderItem>
          <HeaderItem onClick={scrollTo(Section.registry)}>Registry</HeaderItem>
        </HeaderItems>
      </Header>
      {isSticky && <MobileHeaderBuffer />}
    </>
  );
};
